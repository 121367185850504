import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import * as adminServices from "../../helper/callAdminApi";
import "./Survey.css";
import { useNavigate } from "react-router-dom";
import { timestampToFullDate } from "../../helper/function";
import { Button } from "../../components/button/Button";

export const SurveyList = (props) => {
  const [surveys, setSurveys] = useState([]);
  const [activeSearch, setActiveSearch] = useState(false);
  let navigate = useNavigate();
  useEffect(() => {
    const fetchData = async () => {
      let { results } = await adminServices.getSurveys();
      setSurveys(results);
    };
    fetchData();
  }, []);
  const handleCreateSurvey = async () => {
    let result;
    try {
      result = await adminServices.createSurvey();
    } catch (err) {
      return alert(err.message);
    }
    navigate(`/survey/detail/${result.id}`);
  };
  const handleRemoveSurvey = async (id) => {
    let [matchSurvey] = surveys.filter((i) => i.id === id);
    let update = window.confirm(`Bạn xác nhận xóa khảo sát "${matchSurvey.surveyName}"?`);
    if (!update) return;
    try {
      await adminServices.removeSurvey({ id });
    } catch (err) {
      return alert(err.message);
    }
    let newSurveys = surveys.filter((survey) => survey.id !== id);
    setSurveys(newSurveys);
  };

  const handleActiveSurvey = async (id) => {
    let newSurveys = await Promise.all(
      surveys.map(async (survey) => {
        if (survey.id === id) {
          let updateData = { id, isActive: !survey.isActive };
          await adminServices.updateSurvey({ updateData });
          survey.isActive = !survey.isActive;
        }
        return survey;
      })
    );
    setSurveys(newSurveys);
  };

  return (
    <div className="surveys-list">
      <div className="surveys-list__header">
        <h2 className="header__content">Danh sách khảo sát</h2>
        <div className="surveys-list__actions">
          <button className="search-button" onClick={() => setActiveSearch(!activeSearch)}>
            <i className="bx bx-search-alt-2"></i>
          </button>
          <button className="create-survey-button" onClick={handleCreateSurvey}>
            <i className="bx bx-list-plus"></i>
          </button>
        </div>
      </div>
      <div className={`search-input ${activeSearch ? "active" : ""}`}>
        <input placeholder="Tìm kiếm..." />
        <button className="search-button">
          <i className="bx bx-search-alt-2"></i>
        </button>
      </div>
      <div className="surveys-list__table">
        <table>
          <thead>
            <tr>
              <th className="check-all-surveys">
                <input type="checkbox" className="check-all" />
              </th>
              <th>Mã khảo sát</th>
              <th className="th-survey-name">Tên khảo sát</th>
              <th className="th-created-by">Người tạo</th>
              <th className="th-question-length">Số câu hỏi</th>
              <th className="th-survey-status">Trạng thái</th>
              <th>Thao tác</th>
            </tr>
          </thead>
          <tbody>
            {surveys.map((survey, index) => {
              return (
                <tr key={index}>
                  <td className="check-survey">
                    <input type="checkbox" />
                  </td>
                  <td>
                    <Link className="td-survey-id" to={`/survey/detail/${survey.id}`}>
                      {survey.surveyId}
                    </Link>
                    <p className="survey-created-time">{timestampToFullDate(survey.createdTime)}</p>
                  </td>
                  <td className="td-survey-name">{survey.surveyName}</td>
                  <td className="td-created-by">{survey.createdBy}</td>
                  <td className="td-question-length">{survey.questions.length}</td>
                  <td className="survey-status">
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        id="flexSwitchCheckChecked"
                        defaultChecked={survey.isActive}
                        onClick={() => handleActiveSurvey(survey.id)}
                      />
                    </div>
                  </td>
                  <td className="table-actions">
                    <Link className="edit-survey" to={`/survey/detail/${survey.id}`}>
                      <i className="bx bxs-edit"></i>
                    </Link>
                    <Button
                      className="delete-survey"
                      onClick={() => handleRemoveSurvey(survey.id)}
                      content={<i className="bx bxs-trash"></i>}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};
