import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useParams, useNavigate } from "react-router-dom";
import { Question } from "../../components/question/Question";
import * as adminServices from "../../helper/callAdminApi";
import { CONST_CONFIG } from "../../helper/config";
import { toBase64 } from "../../helper/function";

export const SurveyDetail = (props) => {
  let params = useParams();
  let { id } = params;
  let navigate = useNavigate();
  let [surveyDetail, setSurveyDetail] = useState({});
  let [currentDrag, setCurrentDrag] = useState(null);
  let [onChangeQuestionId, setOnChangeQuestionId] = useState("surveyName");
  let [errors, setErrors] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      if (!id) return navigate("/survey/list");
      let survey;
      try {
        survey = await adminServices.getSurveyDetail({ id });
      } catch (err) {
        console.log(err.message);
      }
      if (!survey) return navigate("/survey/list");
      setSurveyDetail(survey);
    };
    fetchData();
  }, [navigate, id]);

  useEffect(() => {
    if (!surveyDetail.questions) return;
    let missingAnswerQuestion = surveyDetail.questions.reduce((total, current, index) => {
      if (current.answers.every((i) => !i.isCorrect)) return [...total, index + 1];
      return total;
    }, []);
    if (!missingAnswerQuestion.length) return setErrors([]);
    setErrors(["Câu hỏi chưa có đáp án: " + missingAnswerQuestion.join(", ")]);
  }, [surveyDetail]);

  const addNewQuestion = async ({ duplicateQuestionId }) => {
    let survey;
    let updateData = { surveyId: surveyDetail.surveyId };
    if (duplicateQuestionId) updateData.duplicateQuestionId = duplicateQuestionId;
    try {
      survey = await adminServices.createQuestion(updateData);
    } catch (err) {
      return console.log(err.message);
    }
    if (survey) {
      setSurveyDetail(survey);
      let newQuestion = survey.questions[survey.questions.length - 1];
      setOnChangeQuestionId(newQuestion.id);
    }
  };

  const removeQuestion = async (questionId) => {
    let result, questions;
    let { surveyId } = surveyDetail;
    try {
      result = await adminServices.removeQuestion({ surveyId, questionId });
    } catch (err) {
      return console.log(err.message);
    }
    if (result) questions = result.questions || [];
    setSurveyDetail({ ...surveyDetail, questions });
  };

  const onDragStart = (e, index) => {
    setCurrentDrag(surveyDetail.questions[index]);
    e.dataTransfer.effectAllowed = "move";
    e.dataTransfer.setData("text/html", e.target.parentNode);
    e.dataTransfer.setDragImage(e.target.parentNode, 20, 20);
  };

  const onDragOver = (index) => {
    const currentItem = surveyDetail.questions[index] || {};
    if (!currentDrag || currentItem.id === currentDrag.id) return;
    let questions = surveyDetail.questions.filter((question) => question.id !== currentDrag.id);
    questions.splice(index, 0, currentDrag);
    setSurveyDetail({ ...surveyDetail, questions });
  };

  const onDragEnd = async () => {
    setCurrentDrag(null);
    let questions = surveyDetail.questions.map(({ questionId }) => questionId);
    let updateData = { id: surveyDetail.id, questions };
    await adminServices.updateSurvey({ updateData });
  };

  const handleClickEdit = (id) => {
    setOnChangeQuestionId(id);
  };

  const handleQuestionUpdate = (newQuestion = {}) => {
    let questions = surveyDetail.questions.map((question) => {
      if (question.id !== newQuestion.id) return question;
      return { ...question, ...newQuestion };
    });
    setSurveyDetail({ ...surveyDetail, questions });
  };

  const handleSurveyNameChange = async (e) => {
    setSurveyDetail({ ...surveyDetail, surveyName: e.target.value });
  };

  const handleUpdateSurveyName = async (e) => {
    let updateData = { id: surveyDetail.id, surveyName: e.target.value };
    console.log(updateData);
    await adminServices.updateSurvey({ updateData });
  };

  const handleUploadBanner = async (e) => {
    let file = e.target.files[0];
    let image = await toBase64(file);
    let banner = await adminServices.uploadSurveyImage({ image });
    let updateData = { id: surveyDetail.id, banner };
    await adminServices.updateSurvey({ updateData });
    setSurveyDetail({ ...surveyDetail, banner });
  };

  return (
    <div className="survey-update">
      <div className="survey-header">
        <h2 className="header__content">Chỉnh sửa khảo sát</h2>
        <div className="survey-actions">
          <button className="add-question" onClick={addNewQuestion}>
            <i className="bx bx-plus-circle"></i>
          </button>
          <button className="preview-survey">
            <a
              className="survey-link"
              href={CONST_CONFIG.SURVEY_VIEW_URL + surveyDetail.slug}
              target="_blank"
              rel="noreferrer"
            >
              <i className="bx bx-link-external"></i>
            </a>
          </button>
        </div>
      </div>
      <div className="error-container">
        {errors.map((err, index) => {
          return <div key={index}>*{err}</div>;
        })}
      </div>
      <div className="survey-banner">
        <label className="banner-input">
          <input type="file" onChange={(e) => handleUploadBanner(e)} />
          <i className="bx bx-plus-circle add-banner"></i>
        </label>
        <div className="banner-container">
          {surveyDetail.banner && <img src={CONST_CONFIG.SURVEY_BANNER_URL + surveyDetail.banner} alt="banner" />}
        </div>
      </div>
      <div className="survey-content">
        <div className="update-name" onClick={() => handleClickEdit("surveyName")}>
          {onChangeQuestionId === "surveyName" ? (
            <input
              autoFocus={true}
              value={surveyDetail.surveyName || ""}
              placeholder="Tên khảo sát"
              onChange={handleSurveyNameChange}
              onBlur={handleUpdateSurveyName}
            />
          ) : (
            <div className="survey-name">{surveyDetail.surveyName || "Tên khảo sát"}</div>
          )}
        </div>
        <div className="questions-list">
          {surveyDetail.questions &&
            surveyDetail.questions.map((question, index) => {
              return (
                <div
                  className="question-container"
                  key={index}
                  onDragOver={() => onDragOver(index)}
                  onClick={() => handleClickEdit(question.id)}
                >
                  <div
                    className="draggable-container"
                    draggable
                    onDragEnd={onDragEnd}
                    onDragStart={(e) => onDragStart(e, index)}
                  >
                    <i className="bx bx-dots-horizontal-rounded "></i>
                  </div>
                  <Question
                    questionDetail={question}
                    onRemove={removeQuestion}
                    isEdit={onChangeQuestionId === question.id}
                    handleUpdate={handleQuestionUpdate}
                    addNewQuestion={addNewQuestion}
                    questionCount={index + 1}
                  />
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};
