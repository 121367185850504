import "./App.css";
import { Sidebar } from "./components/sidebar/Sidebar.js";
import { BrowserRouter } from "react-router-dom";
import { MainScreen } from "./features/main/MainScreen.js";
import { useState } from "react";

function App() {
  const [sidebarActive, setSidebarActive] = useState(false);
  const handleActive = () => {
    setSidebarActive(!sidebarActive);
  };
  return (
    <BrowserRouter>
      <div className="App">
        <Sidebar sidebarActive={sidebarActive} handleActive={handleActive} />
        <MainScreen sidebarActive={sidebarActive} />
      </div>
    </BrowserRouter>
  );
}

export default App;
