import React from "react";
import { SurveyList } from "../survey/SurveyList.js";
import "./MainScreen.css";
import { Routes, Route } from "react-router-dom";
// import { Survey } from "../survey/SurveyView.js";
import { Homepage } from "../homepage/Homepage.js";
import { SurveyDetail } from "../survey/SurveyDetail.js";

export const MainScreen = ({ sidebarActive }) => {
  return (
    <div className={sidebarActive ? "main active" : "main"}>
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/survey/list" element={<SurveyList />} />
        <Route path="/survey/detail/:id" element={<SurveyDetail />} />
        <Route path="*" element={<div>Hello</div>} />
      </Routes>
    </div>
  );
};
