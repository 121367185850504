import axios from "axios";
import { ADMIN_SERVICES } from "./config";

const adminServices = axios.create({ baseURL: ADMIN_SERVICES.BASE_URL });

export const createSurvey = async (data) => {
  let result;
  try {
    result = await adminServices.post(ADMIN_SERVICES.ENDPOINT.SURVEY_CREATE);
  } catch (err) {
    console.log(err.response);
    throw new Error(err.message);
  }
  return result.data.data;
};

export const removeSurvey = async ({ id }) => {
  try {
    await adminServices.delete(ADMIN_SERVICES.ENDPOINT.SURVEY_REMOVE + "/" + id);
  } catch (err) {
    console.log(err.response);
    throw new Error(err.message);
  }
  return true;
};

export const getSurveys = async (data) => {
  let results;
  try {
    results = await adminServices.get(ADMIN_SERVICES.ENDPOINT.SURVEY_LIST);
  } catch (err) {
    throw new Error(err.message);
  }
  return results.data.data;
};

export const getQuestionTypes = async () => {
  let results;
  try {
    results = await adminServices.get(ADMIN_SERVICES.ENDPOINT.SERVICES_QUESTION_TYPE);
  } catch (err) {
    throw new Error(err.message);
  }
  return results.data.data;
};

export const getSurveyDetail = async (data) => {
  let { id } = data;
  let result;
  try {
    result = await adminServices.get(ADMIN_SERVICES.ENDPOINT.SURVEY_DETAIL + `?id=${id}`);
  } catch (err) {
    throw new Error(err.message);
  }
  return result.data.data;
};

export const createQuestion = async (data) => {
  let { surveyId, duplicateQuestionId } = data;
  let result;
  let body = { surveyId, duplicateQuestionId };
  try {
    result = await adminServices.post(ADMIN_SERVICES.ENDPOINT.SURVEY_QUESTION_CREATE, body);
  } catch (err) {
    throw new Error(err.message);
  }
  return result.data.data;
};

export const removeQuestion = async (data) => {
  let { surveyId, questionId } = data;
  let result;
  try {
    result = await adminServices.delete(ADMIN_SERVICES.ENDPOINT.SURVEY_QUESTION_REMOVE + `/${surveyId}/${questionId}`);
  } catch (err) {
    throw new Error(err.message);
  }
  return result.data.data;
};

export const updateQuestion = async (data) => {
  let { updateData } = data;
  let result;
  try {
    result = await adminServices.put(ADMIN_SERVICES.ENDPOINT.SURVEY_QUESTION_UPDATE, updateData);
  } catch (err) {
    throw new Error(err.message);
  }
  return result.data.data;
};

export const updateSurvey = async (data) => {
  let { updateData } = data;
  let result;
  try {
    result = await adminServices.put(ADMIN_SERVICES.ENDPOINT.SURVEY_UPDATE, updateData);
  } catch (err) {
    throw new Error(err.message);
  }
  return result.data.data;
};

export const uploadSurveyImage = async ({ image }) => {
  let updateData = { image };
  let result;
  try {
    result = await adminServices.post(ADMIN_SERVICES.ENDPOINT.SERVICES_UPLOAD_SURVEY_IMAGE, updateData);
  } catch (err) {
    throw new Error(err.message);
  }
  return result.data.data;
};
