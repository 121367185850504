export const SURVEY_CONFIG = {
  DEPARTMENT: {
    A: "Khối Văn Phòng",
    B: "Khối cửa hàng Hà Nội",
    C: "Khối cửa hàng Hồ Chí Minh",
  },
  OFFICE: {
    A: "VP. Vận hành",
    B: "VP. HCNS",
    C: "VP. Kho",
    D: "VP. Telesales",
    E: "VP. Kế toán",
    F: "VP. Marketing",
    G: "VP. Technology",
    H: "VP Sản xuất",
    I: "VP Thiết kế",
  },
  STORE: {
    A: "Cửa hàng Tô Hiến Thành",
    B: "Cửa hàng Cầu Giấy",
    C: "Cửa hàng Đặng Văn Ngữ",
    D: "Cửa hàng Đông Các",
    E: "Cửa hàng Nguyễn Gia Trí",
    F: "Cửa hàng Nguyễn Trãi",
  },
  QUESTIONS: [
    {
      question: "Theo bạn, ba chữ “SSS” trong tên thương hiệu “SSSTUTTER” là viết tắt cho những từ nào?",
      answer: {
        A: "Skill (Kỹ năng) – Sage (Thông thái) – Share (Chia sẻ)",
        B: "Sage (Thông thái) – Share (Chia sẻ) – Success (Thành công)",
        C: "Sympathy (Thông cảm) – Share (Chia sẻ) – Suceess (Thành công)",
      },
      correct: ["B"],
    },
    {
      question: "Cửa hàng đầu tiên của SSSTUTTER tại đâu?",
      answer: {
        A: "Hoàng Cầu",
        B: "Tôn Thất Thiệp",
        C: "Thợ Nhuộm",
      },
      correct: ["A"],
    },
    {
      question: "Sứ mệnh của SSSTUTTER là gì?",
      answer: {
        A: "Giúp giới trẻ nâng tầm giá trị bản thân bằng các sản phẩm Tinh gọn.",
        B: "Giúp giới trẻ càng ngày càng trở nên Tinh gọn và Bảnh bao hơn.",
        C: "Giúp giới trẻ Việt Nam nâng tầm giá trị bản thân bằng các sản phẩm Tinh gọn.",
      },
      correct: ["C"],
    },
    {
      question: "Tầm nhìn của SSSTUTTER ?",
      answer: {
        A: "Trở thành công ty số 1 tại Việt Nam đem đến các sản phẩm tinh gọn cho giới trẻ.",
        B: "Trở thành công ty hàng đầu Việt Nam về Thời trang Tinh gọn.",
        C: "Trở thành một trong những công ty hàng đầu về Thời trang.",
      },
      correct: ["A"],
    },
    {
      question: "Nhân viên công ty sẽ được nhận lương dưới hình thức nào ?",
      answer: {
        A: "Trả lương bằng tiền mặt",
        B: "Trả lương qua tài khoản cá nhân của từng nhân viên.",
        C: "Trả lương qua tài khoản VP bank.",
      },
      correct: ["C"],
    },
    {
      question: "Khi gặp vấn đề, thắc mắc về lương thưởng, phúc lợi... thì CBNV liên hệ với ai ?",
      answer: {
        A: "Chủ động tìm hiểu, kiểm tra kỹ lại xem thắc mắc của mình chính xác chưa -> Gửi thông tin tới Trưởng phòng của mình -> Trưởng phòng tổng hợp thông tin và gửi về cho Nhân sự.",
        B: "Chủ động tìm hiểu, kiểm tra kỹ lại xem thắc mắc của mình chính xác chưa -> Liên hệ trực tiếp với Trưởng phòng Nhân sự để hỏi.",
        C: "Chủ động tìm hiểu, kiểm tra kỹ lại xem thắc mắc của mình chính xác chưa -> Liên hệ nhân viên C&B phụ trách lương thưởng để hỏi.",
      },
      correct: ["A"],
    },
    {
      question:
        "Weekly là hoạt động để gắn kết anh em, đồng nghiệp có môi trường trao đổi, trò chuyện, gần gũi và hiểu nhau hơn. Hoạt động này thường diễn ra vào thời gian nào trong tuần ?",
      answer: {
        A: "Chiều thứ 2 hàng tuần, từ 17 – 18h.",
        B: "Chiều thứ 4 hàng tuần, từ 17 – 18h.",
        C: "Sáng thứ 7 hàng tuần, sau khi kết thúc ca sáng.",
        D: "Chiều thứ 6 hàng tuần, từ 15 – 16h.",
      },
      correct: ["B"],
    },
    {
      question: "Trường hợp nào dưới đây không ghi nhận giải trình chấm công ?",
      answer: {
        A: "Máy chấm công bị hỏng/lỗi.",
        B: "Làm việc tại nơi chưa có vân tay.",
        C: "Quên chấm vân tay.",
      },
      correct: ["C"],
    },
    {
      question: "Một nhân viên Fulltime tới công ty chấm công vào 8h45 thì thời gian được ra về là ?",
      answer: {
        A: "17h",
        B: "16h45",
        C: "17h45",
        D: "18h45",
      },
      correct: ["C"],
    },
    {
      question: "Nhân viên làm việc tại SSSTUTTER khi mua hàng tại công ty được chiết khấu bao nhiêu phần trăm ?",
      answer: {
        A: "20%",
        B: "25%",
        C: "30%",
        D: "10%",
      },
      correct: ["B"],
    },
    {
      question: "SSSTUTTER hiện có bao nhiêu kênh truyền thông và bán hàng online chính thức ?",
      answer: {
        A: 5,
        B: 6,
        C: 7,
        D: 8,
      },
      correct: ["A"],
    },
    {
      question: "Chính sách đóng bảo hiểm cho nhân viên tại SSSTUTTER như thế nào ?",
      answer: {
        A: "Tất cả nhân viên đóng sau 6 tháng.",
        B: "Từ cấp trưởng nhóm, giám đốc đóng sau 2 tháng thử việc, nhân viên fulltime đóng sau 6 tháng và không áp dụng với parttime.",
        C: "Tất cả nhân viên được đóng sau thời gian thử việc.",
      },
      correct: ["B"],
    },
    {
      question:
        "Khi gặp khó khăn trong việc sử dụng và đăng ký tài khoản Workchat (ứng dụng trao đổi công việc tại công ty), bạn cần liên hệ với ai ?",
      answer: {
        A: "Trưởng phòng Nhân sự ( Ms. Nhung)",
        B: "Trưởng bộ phận của bạn.",
        C: "Nhân viên phòng HCNS (Ms. Vân và Ms. Phương Anh)",
        D: "Hỏi đồng nghiệp trong phòng.",
      },
      correct: ["C"],
    },
    {
      question: "Ai là người phụ trách bảo quản, giữ gìn và sử dụng hợp lý các thiết bị, tài sản chung của công ty?",
      answer: {
        A: "Nhân viên C&B (Ms.Vân)",
        B: "CBNV trực tiếp sử dụng thiết bị hoặc ngồi làm việc tại khu vực sử dụng thiết bị đó.",
        C: "Trưởng các Bộ phận.",
      },
      correct: ["B"],
    },
    {
      question:
        "Khi các thiết bị điện tử thuộc khu vực làm việc của bạn (điều hòa, máy in, máy chiếu,...) gặp trục trặc hoặc bạn chưa hiểu cách sử dụng, thì bạn cần liên hệ với ai?",
      answer: {
        A: "Hỏi đồng nghiệp.",
        B: "Liên hệ nhân viên C&B phòng HCNS (Ms. Vân)",
        C: "Gặp trực tiếp Trưởng phòng Nhân sự (Ms. Nhung)",
      },
      correct: ["B"],
    },
    {
      question: "Sau khi tổ chức ăn uống, liên hoan tại công ty, CBNV nào có trách nhiệm dọn dẹp?",
      answer: {
        A: "Liên hệ phòng HCNS.",
        B: "Liên hệ thuê người về dọn dẹp.",
        C: "CBNV có trách nhiệm cùng dọn dẹp sạch sẽ, đặc biệt tại vị trí làm việc của mình.",
      },
      correct: ["C"],
    },
    {
      question: "Khi muốn xin nghỉ sử dụng ngày phép, CBNV cần làm gì?",
      answer: {
        A: "Nhắn tin và xin phép Trưởng bộ phận của mình.",
        B: "Nhắn tin và xin phép Trưởng phòng Nhân sự. (Ms. Nhung).",
        C: "Làm đề xuất xin nghỉ, nhắn tin thông báo với Trưởng bộ phận và Trưởng phòng Nhân sự (Ms. Nhung)",
      },
      correct: ["C"],
    },
    {
      question: "Thời gian nhân viên được xét duyệt lương định kỳ?",
      answer: {
        A: "Định kỳ 1 năm/ lần.",
        B: "Định kỳ 6 tháng/ lần.",
        C: "Khi CBNV yêu cầu được xét duyệt tăng lương.",
      },
      correct: ["A"],
    },
    {
      question: "Các đối tượng được áp dụng xét duyệt tăng lương:",
      answer: {
        A: "Tất cả nhân viên thuộc công ty.",
        B: "Chỉ áp dụng với nhân viên Fulltime.",
        C: "Áp dụng với CBNV Fulltime văn phòng và nhân viên khối cửa hàng làm việc từ 6 tháng.",
      },
      correct: ["C"],
    },
    {
      question:
        "Thông tin về lương thưởng của mỗi cá nhân đều cần được bảo mât. Ai là người được biết và trao đổi về thu nhập cá nhân?",
      answer: {
        A: "Tổng giám đốc, Giám đóc, Quản lý trực tiếp và đồng nghiệp cùng phòng ban.",
        B: "Tổng giám đốc, Giám đốc, Quản lý trực tiếp, Nhân sự, Kế toán.",
        C: "Tổng giám đốc, Giám đốc, Quản lý trực tiếp, Nhân sự, Kế toán và đồng nghiệp cùng phòng ban.",
        D: "Tổng giám đốc, Giám đốc, Quản lý trực tiếp và Nhân sự.",
      },
      correct: ["B"],
    },
  ],
  COMMENT: [
    {
      min: 0,
      max: 5,
      comment:
        "Cảm ơn bạn đã hoàn thành bài test. Ở thời điểm hiện tại, bạn vẫn còn chưa tập trung và chủ động tìm hiểu, học hỏi thông tin. Với số điểm này bạn sẽ cần phải thật sự tập trung và chủ động nhiều hơn nữa để có thể bắt kịp với môi trường mới tại SSStutter. Mong rằng trong thời gian tới, bạn sẽ cố gắng thật nhiều để đạt được kết quả gây “wow” cho chúng mình nhé.",
    },
    {
      min: 6,
      max: 11,
      comment:
        "Cảm ơn bạn đã hoàn thành bài test. Đối với kết quả này, SSStutter thấy rằng bạn cần phải chủ động nhiều hơn nữa trong việc tìm hiểu và học hỏi thông tin để bắt kịp với môi trường mới tại SSStutter. Thời gian tới, hãy thể hiện tốt hơn bạn nhé!",
    },
    {
      min: 12,
      max: 16,
      comment:
        "Cảm ơn bạn đã hoàn thành bài test khá tốt. Tuy nhiên, SSStutter vẫn mong rằng bạn sẽ chủ động tìm hiểu thông tin chính xác hơn . Thời gian tới, không chỉ giữ vững phong độ, mà hãy bứt phá hơn nữa để SSS thật bất ngờ bạn nhé.",
    },
    {
      min: 17,
      max: 20,
      comment:
        "SSStutter đánh giá rất cao sự chủ động của bạn trong việc tìm hiểu và học hỏi thông tin mới. Hãy luôn giữ vững phong độ trong suốt quá trình đồng hành cùng nhau, bạn nhé.",
    },
  ],
};

export const CONST_CONFIG = {
  SURVEY_VIEW_URL: "https://ssstutter.leanservices.work/orientation/",
  SURVEY_BANNER_URL: "https://admin.complaint.leanservices.work/static/",
};

export const SIDEBAR_LIST = [
  {
    name: "Trang chủ",
    link: "/",
    icon: "bx bxs-home",
  },
  {
    name: "Khảo sát",
    link: "/survey/list",
    icon: "bx bx-check-square",
  },
  // {
  //   name: "Báo cáo",
  //   link: "",
  //   icon: "bx bxs-home",
  // },
  // {
  //   name: "Sản phẩm",
  //   link: "",
  //   icon: "bx bxs-home",
  // },
];

export const ADMIN_SERVICES = {
  BASE_URL: "https://admin.complaint.leanservices.work",
  AUTHORIZATION: "by-passs",
  ENDPOINT: {
    SURVEY_CREATE: "/survey/create",
    SURVEY_UPDATE: "/survey/update",
    SURVEY_LIST: "/survey/search",
    SURVEY_DETAIL: "/survey/detail",
    SURVEY_REMOVE: "/survey/remove",
    SURVEY_QUESTION_CREATE: "/survey/question/create",
    SURVEY_QUESTION_UPDATE: "/survey/question/update",
    SURVEY_QUESTION_REMOVE: "/survey/question/remove",
    SERVICES_QUESTION_TYPE: "/services/question-types",
    SERVICES_UPLOAD_SURVEY_IMAGE: "/services/upload-survey-image",
  },
};
