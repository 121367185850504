import React from "react";
import { SIDEBAR_LIST } from "../../helper/config.js";
import "./Sidebar.css";
import { Link } from "react-router-dom";

export const Sidebar = ({ handleActive, sidebarActive }) => {
  return (
    <div className={sidebarActive ? "sidebar active" : "sidebar"}>
      <div className="logo_content">
        <div className="logo">
          <i className="bx bxl-sketch"></i>
          <div className="logo_name">SSStutter</div>
        </div>
        <i className="bx bx-menu" id="btn" onClick={handleActive}></i>
      </div>
      <ul>
        {SIDEBAR_LIST.map((nav, index) => (
          <li key={index}>
            <Link className="nav_item" to={nav.link}>
              <i className={nav.icon.toString()}></i>
              <span>{nav.name}</span>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};
