import React from "react";
import { Button } from "../button/Button";
import "./Question.css";

export const AnswerEdit = ({ type, answers = [], onUpdate, onAddAnswer }) => {
  const updateAnswers = (e, index) => {
    let value = e.target.value;
    if (e.target.type === "checkbox") value = e.target.checked;
    let newAnswers = answers.map((answer, i) => {
      if (i === index) answer[e.target.name] = value;
      return answer;
    });
    onUpdate("answers", newAnswers);
  };

  const handleRemoveAnswer = (index) => {
    let newAnswers = answers.filter((a, i) => i !== index);
    onUpdate("answers", newAnswers);
  };

  if (type === "input") {
    return (
      <>
        <input type="text" placeholder="Input..." disabled />
      </>
    );
  }

  if (type === "paragraph") {
    return (
      <>
        <textarea value="Đoạn văn bản..." disabled />
      </>
    );
  }

  if (["input", "paragraph"].includes(type)) return;
  // if (type === "checkbox") {
  //   return (
  //     <>
  //       {answers.map((answer, index) => {
  //         return (
  //           <div key={index} className="checkbox-answer">
  //             <input type="checkbox" disabled />
  //             <input
  //               className="answer-input"
  //               value={answer.answer}
  //               name="answer"
  //               onChange={(e) => updateAnswers(e, index)}
  //             />
  //             <Button onClick={() => handleRemoveAnswer(index)} content="Xoá câu trả lời" />
  //             <input
  //               type="checkbox"
  //               id={`is-correct-${index}`}
  //               name="isCorrect"
  //               onChange={(e) => updateAnswers(e, index)}
  //               checked={answer.isCorrect}
  //             />
  //             <label htmlFor={`is-correct-${index}`}>Đáp án</label>
  //           </div>
  //         );
  //       })}
  //     </>
  //   );
  // }
  if (["radio", "dropdown", "checkbox"].includes(type)) {
    return (
      <>
        {answers.map((answer, index) => {
          return (
            <div key={index} className="answer-container">
              <div className="answer-content">
                <input
                  type="checkbox"
                  name="isCorrect"
                  onChange={(e) => updateAnswers(e, index)}
                  defaultChecked={answer.isCorrect}
                />
                <input
                  className="answer-input"
                  value={answer.answer}
                  name="answer"
                  onChange={(e) => updateAnswers(e, index)}
                />
              </div>

              <Button
                className="delete-answer-action"
                onClick={() => handleRemoveAnswer(index)}
                content={<i className="bx bx-x"></i>}
              />
            </div>
          );
        })}
        <Button className="add-answer" onClick={onAddAnswer} content={<i className="bx bx-plus-circle"></i>} />
      </>
    );
  }
  // if (type === "dropdown") {
  //   return (
  //     <>
  //       {answers.map((answer, index) => {
  //         return (
  //           <div key={index} className="dropdown-answer">
  //             <span>{index + 1}.</span>
  //             <input
  //               className="answer-input"
  //               key={index}
  //               value={answer.answer}
  //               name="answer"
  //               onChange={(e) => updateAnswers(e, index)}
  //             />
  //             <Button onClick={() => handleRemoveAnswer(index)} content="Xoá câu trả lời" />
  //             <input
  //               type="checkbox"
  //               name="isCorrect"
  //               id={`is-correct-${index}`}
  //               onChange={(e) => updateAnswers(e, index)}
  //               checked={answer.isCorrect}
  //             />
  //             <label htmlFor={`is-correct-${index}`}>Đáp án</label>
  //           </div>
  //         );
  //       })}
  //     </>
  //   );
  // }
  if (type === "date") {
    return <input type="date" />;
  }
  return;
};

export const AnswerView = ({ type, answers = [] }) => {
  if (type === "input") {
    return (
      <>
        <input type="text" placeholder="Input..." disabled />
      </>
    );
  }

  if (type === "paragraph") {
    return (
      <>
        <textarea value="Đoạn văn bản..." disabled />
      </>
    );
  }

  if (["radio", "dropdown", "checkbox"].includes(type)) {
    return (
      <>
        {answers.map((answer, index) => {
          return (
            <div key={index} className="answer-content">
              <input type="checkbox" name="isCorrect" checked={answer.isCorrect} disabled />
              <label className="answer-input">{answer.answer}</label>
            </div>
          );
        })}
      </>
    );
  }
  if (type === "date") {
    return <input type="date" />;
  }
  return;
};
