import React, { useState, useEffect } from "react";
import { Button } from "../button/Button";
import { AnswerEdit, AnswerView } from "./Answer";
import * as adminServices from "../../helper/callAdminApi";
import "./Question.css";

export const Question = ({ questionDetail = {}, onRemove, isEdit, handleUpdate, addNewQuestion, questionCount }) => {
  const [question, setQuestion] = useState(questionDetail);
  const [questionTypes, setQuestionTypes] = useState([]);
  const [timer, setTimer] = useState(null);

  useEffect(() => {
    setQuestion(questionDetail);
  }, [questionDetail]);

  useEffect(() => {
    if (!isEdit || questionTypes.length) return;
    const getQuestionTypes = async () => {
      let results;
      try {
        results = await adminServices.getQuestionTypes();
      } catch (err) {
        return console.log(err.message);
      }
      if (!results) return;
      setQuestionTypes(results);
    };
    getQuestionTypes();
  }, [isEdit, questionTypes]);

  const handleRemoveQuestion = () => {
    if (timer) clearTimeout(timer);
    onRemove(question.questionId);
  };

  const onAddAnswer = () => {
    let answers = [...question.answers, {}];
    const updateQuestion = async () => {
      let result;
      let updateData = { id: question.id, answers };
      try {
        result = await adminServices.updateQuestion({ updateData });
      } catch (err) {
        return console.log(err.message);
      }
      if (!result) return;
      handleUpdate(result);
    };
    updateQuestion();
  };

  const onUpdate = (key, value) => {
    let newQuestion = { ...question, [key]: value };
    setQuestion(newQuestion);
    handleUpdate(newQuestion);
    const updateQuestion = async () => {
      let updateData = { id: question.id, [key]: value };
      try {
        await adminServices.updateQuestion({ updateData });
      } catch (err) {
        return console.log(err.message);
      }
    };
    if (timer) clearTimeout(timer);
    setTimer(setTimeout(() => updateQuestion(), 1000));
  };

  if (isEdit) {
    return (
      <div className="input-question">
        <div className="question-detail">
          <textarea
            value={question.content}
            autoFocus={true}
            type="text"
            className="question-content-input"
            placeholder="Câu hỏi"
            name="content"
            onFocus={(e) => {
              e.target.style.height = "48px";
              let scHeight = e.target.scrollHeight;
              e.target.style.height = `${scHeight}px`;
            }}
            onKeyUp={(e) => {
              e.target.style.height = "48px";
              let scHeight = e.target.scrollHeight;
              e.target.style.height = `${scHeight}px`;
            }}
            onChange={(e) => {
              onUpdate(e.target.name, e.target.value);
            }}
          ></textarea>

          <select
            className="question-type-dropdown"
            name="type"
            onChange={(e) => onUpdate(e.target.name, e.target.value)}
            value={question.type}
          >
            {questionTypes.map((type) => {
              return (
                <option key={type.type} value={type.type}>
                  {type.name}
                </option>
              );
            })}
          </select>
        </div>
        <div className="question-answers">
          <AnswerEdit type={question.type} answers={question.answers} onUpdate={onUpdate} onAddAnswer={onAddAnswer} />
        </div>
        <div className="question-actions">
          <Button
            className="question-duplicate-button"
            onClick={() => addNewQuestion({ duplicateQuestionId: question.id })}
            content={<i className="bx bx-duplicate"></i>}
          />
          <Button
            className="question-delete-button"
            onClick={handleRemoveQuestion}
            content={<i className="bx bx-trash"></i>}
          />
          <div className="is-required-action">
            <div>Bắt buộc</div>
            <div className="form-check form-switch question-required-button">
              <input
                className="form-check-input"
                type="checkbox"
                role="switch"
                id="flexSwitchCheckChecked"
                defaultChecked={question.isRequired}
                name="isRequired"
                onClick={(e) => onUpdate(e.target.name, !question.isRequired)}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="input-question">
      <div className="question-detail">
        <div className="question-content">
          {`Câu ${questionCount}: `}
          {question.content || "Câu hỏi"}
        </div>
      </div>
      <div className="question-answer">
        <AnswerView type={question.type} answers={question.answers} />
      </div>
    </div>
  );
};
